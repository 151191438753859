import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import headshot1 from "../../assets/images/founder/ej_headshot1.jpg";
import about1 from "../../assets/images/shapes/about-bag-1-1.png";
import about2 from "../../assets/images/myltf/IMG_9630.jpg";
import about3 from "../../assets/images/myltf/IMG_7359.jpg";
import about4 from "../../assets/images/myltf/IMG_9647(1).jpg";

import bgImage from "../../assets/images/stock/AdobeStock_213925805_Preview.jpeg";

const FounderContent = () => {
  return (
    <>
      <section className="event-details pt-120 founder-content">
        <Container>
          <Row>
            <h3>Eric Jackson - Changing lives one shot at a time</h3>
          </Row>
          <Row className="pb-20">
            <h4>INTEGRITY - LEADERSHIP - INCLUSION - COMMUNITY - EXCELLENCE</h4>
          </Row>
          <Row>
            <Col md={12} lg={6}>
              <img
                src={headshot1}
                className="founder-content__text-img img-fluid"
                alt="Team photo"
                height={720}
              />
            </Col>
            <Col md={12} lg={6}>
              <p>
                When I look at these kids on the court I see myself. Kids see me
                and can identify with their doing because I was once in their
                shoes. Now they believe in themselves and I’ve instilled hope. I
                would like to introduce myself: Eric “EJ" Jackson. I am a native
                of Los Angeles, California; I relocated to Atlanta in 1992. I
                started playing tennis when I was four years old. And I was
                talented, but even with talent, I needed constant attention and
                coaching to lead me on the way. And there were several people
                who helped me to reach my goal. I had several wonderful coaches,
                from Richard Williams, Rick Davison (former Georgia Tech coach),
                Nick Saviano (former USTA national coach, and Norman Wilkerson
                just to name a few, who did everything tennis-wise for me:
                taught me, corrected me, and arranged transportation and support
                when I needed it.  My parents were totally in my corner,
                offering encouragement and open-ended support for me as my
                tennis career grew. Later on, there were people who helped me
                beyond tennis: I had academic support from a college professor
                who helped me score well on the SAT and ACT.
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={12} lg={6}>
              <p>
                As my tennis took a more serious bent, I started playing
                tournaments on a weekly basis, around Atlanta and
                beyond.  Again, support and encouragement from those around me
                helped me immensely with the pressure I put on myself to play
                well and win.  Tennis represented me and who I was. Throughout
                high school, I played my junior tennis career in Atlanta. As a
                junior player, I was ranked #1 in Georgia ages 12-14’s, #1 in GA
                doubles ages 12-18’s, Top 5 in GA singles/doubles ages 16-18’s.
                Selected to Challenge Cup in 12’s. In 1993 became GTA Player of
                the Year and won Junior Davis Cup for GA ages 12-18’s. Also, I
                won team Zonals for Southern section ages 12-14’s. My highest
                achievements were being ranked #9 in the nation in 14’s, a
                finalist of the Boys 14’s Clay Court Nationals, USTA 14’s Hard
                Court Doubles Finalist and selected to the US National Team to
                represent the U.S. in Japan at an ITF event.
              </p>
            </Col>
            <Col md={12} lg={6}>
              <img
                src={about4}
                className="founder-content__text-img img-fluid"
                alt="Team photo"
              />
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col md={12} lg={6}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/WWBn4Pa98G4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </Col>
            <Col md={12} lg={6}>
              <p>
                I also finished 3rd place at the Boy’s 16’s USTA National Indoor
                (lost to James Blake in semi’s), 1995 -1996 finished 3rd place
                Intersectional representing Southern age’s 16-18’s. Selected as
                an ALL-High School Elite top 8 player in GA and competed at
                Kalamazoo ages 16-18’s, lost in doubles semi-finals. I became
                top 5 in the nation in doubles and top 30 in the nation in
                singles from ages 12-18’s.
              </p>
            </Col>
          </Row>
          <Row>
            <p>
              Upon graduation from high school, I attended North Carolina State
              University on a full tennis scholarship beginning in 1997. My
              playing experience included 1st Team All – ACC (2000-2001), MVP
              (2000), 3rd All -Time singles wins and the 6th All Time doubles
              wins. My highest national ranking was #16 in doubles and #75 in
              singles. I also helped propelled N.C. State to our first NCAA
              appearance in over 20 years. Upon graduation, I earned a
              Bachelor’s Degree in Agricultural Business Management with a minor
              in Business Management.
            </p>
            <p>
              As I gazed into their eyes, I see the next American Tennis
              Champion. I know there’s a child waiting for someone like myself
              and other coaches to teach, mentor, and train the next great
              champion of “Our Generation.” 
            </p>
          </Row>
        </Container>
      </section>

      <section className="about-one pt-120 pb-40">
        <Container>
          <Row>
            <div className="about-one__award">
              <img src={about1} alt="Playing together" />
            </div>
            <Col lg={6}>
              <img
                src={about2}
                width={570}
                height={272}
                alt=""
                className="img-fluid founder-content-img"
              />
            </Col>
            <Col lg={6}>
              <img
                src={about3}
                width={570}
                height={272}
                alt="Group picture with Eric"
                className="img-fluid founder-content-img"
              />
            </Col>
          </Row>
        </Container>
        <Container>
          <div className="team-about__top mt-60">
            <Row>
              <Col md={12} lg={4}>
                <div className="block-title">
                  <h3>Let’s help them together.</h3>
                </div>
              </Col>
              <Col md={12} lg={4}>
                <p>
                  When I teach kids that come from similar communities and deal
                  with the same challenges that I overlooked as a junior player
                  30 years ago, I see myself. Eric Jackson is the spirit of love
                  of tennis.
                </p>
              </Col>
              <Col md={12} lg={4}>
                <p>
                  Over the years, I’ve embraced my greatness at a unique
                  lifetime-sport. Now having the opportunity to be a service and
                  share tennis love makes my journey invaluable to the City of
                  Atlanta and beyond. I am American Tennis!
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="call-to-action-two">
        <div
          className="call-to-action-two__bg"
          style={{ backgroundImage: `url(${bgImage})` }}
        ></div>
        <Container className="container pt-80 pb-80">
          <i className="azino-icon-charity call-to-action-two__icon"></i>
          <Col lg={7}>
            <div className="block-title">
              <h3>To Serve and To Love, changing lives one shot at a time.</h3>
            </div>
          </Col>
          <div className="btn-wrap">Eric Jackson</div>
        </Container>
      </section>
    </>
  );
};

export default FounderContent;
