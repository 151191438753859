import React from "react";
import Layout from "../../components/layout";
import HeaderTwo from "../../components/header/header-two";
import StickyHeader from "../../components/header/sticky-header";
import FounderContent from "../../components/team/founder-content";
import Footer from "../../components/footer";
import PageHeader from "../../components/page-header";

const HomeTwo = () => {
  return (
    <Layout pageTitle="Made You Love Tennis Foundation | Eric Jackson">
      <HeaderTwo />
      <div className="founder-page">
        <StickyHeader extraClassName="stricky-header-two" />
        <PageHeader title="Eric Jackson" crumbTitle="Eric Jackson" />
        <FounderContent />
      </div>
      <Footer />
    </Layout>
  );
};

export default HomeTwo;
